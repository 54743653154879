import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import CarteLienVersGalerie from "../../composants/navigation/CarteLienVersGalerie"
import LayoutFR from "../../composants/layout/fr/LayoutFR"

import { obtenirPage } from "../../js/client-es/utils"
import fragmentsGaleries from "../../js/client-es/fragments/fragments-galeries"

export const requete = graphql`
  {
    pageYamlIndexGaleries: allPagesCompilationYaml(
      filter: { code: { eq: "indexGaleries" } }
    ) {
      ...FragmentPageYaml
    }
    galeriesYamlCielProfond: allGaleriesCompilationYaml(
      filter: {
        code: {
          in: [
            "top"
            "nebuleusesCouleursNaturelles"
            "nebuleusesPaletteHubble"
            "voieLactee"
            "galaxies"
            "zoomsEt360Degres"
            "phenomenes"
            "auroresBoreales"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentGalerieYaml
    }
  }
`

export default function IndexGalerie() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageYamlIndexGaleries")

  return (
    <LayoutFR page={page}>
      {resultatsRequete.galeriesYamlCielProfond.nodes.map(g => {
        return <CarteLienVersGalerie key={g.code} galerie={g} />
      })}
    </LayoutFR>
  )
}
