import React, { Component } from "react"
import PropTypes from "prop-types"

import { GatsbyImage } from "gatsby-plugin-image"

import Lien from "./Lien"

export default class CarteGalerie extends Component {
  constructor(props) {
    super(props)
    this.galerie = props.galerie
  }

  componentDidMount() {
    let titreCarte = document.getElementById(`titre-${this.galerie.code}`)
    titreCarte.onmouseleave = this.assombrirVignette.bind(this)
    titreCarte.onmouseenter = this.eclaircirVignette.bind(this)
    let imageCarte = document.getElementById(`img-${this.galerie.code}`)
    imageCarte.onmouseleave = this.assombrirVignette.bind(this)
    imageCarte.onmouseenter = this.eclaircirVignette.bind(this)
  }

  assombrirVignette() {
    let element = document.querySelector(`#${this.galerie.code} picture`)
    if (element != null) {
      element.style.opacity = 1
    }
  }

  eclaircirVignette() {
    let element = document.querySelector(`#${this.galerie.code} picture`)
    if (element != null) {
      element.style.opacity = 0.95
    }
  }

  render() {
    return (
      <section className="galerie-carte-presentation" id={this.galerie.code}>
        <h3 className="galerie-carte-presentation-titre">
          <Lien
            codePageCible={this.props.galerie.code_page}
            id={`titre-${this.galerie.code}`}
          >
            {this.galerie.titre}
          </Lien>
        </h3>

        <div className="galerie-carte-presentation-contenu">
          <p id={`p-${this.galerie.code}`}>
            <Lien codePageCible={this.galerie.code_page}>
              {this.galerie.description}
            </Lien>
          </p>
        </div>

        <div className="img" id={`img-${this.galerie.code}`}>
          <Lien codePageCible={this.galerie.code_page}>
            <GatsbyImage
              image={this.galerie.vignette.childImageSharp.gatsbyImageData}
              alt={this.galerie.titre}
            />
          </Lien>
        </div>
      </section>
    )
  }
}

CarteGalerie.propTypes = {
  galerie: PropTypes.object,
}
